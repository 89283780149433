<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('hall.default[3]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="header0">
      <div class="MyHeader2">
        <img class="head" :src="`./static/head/${UserInfo.header}`" />
      </div>
      <div>{{ UserInfo.username}}</div>
    </div>
    <div class="infoBox" style="line-height: 25px">
      <div>
        <!--              <div style="font-size:16px;color: var(&#45;&#45;font_color)">{{ UserInfo.username.substring(0,15) }}</div>-->
        <div>{{$t('bindAccount.fields[0]')}} :{{CardInfo.name}}</div>
      </div>
      <div>
        <div style="font-size:15px"> USDT {{ $t("hall.default[1]") }}:
          <span style="color: var(--font_color2);font-weight: bold">{{ parseFloat(UserInfo.balance).toFixed(2)}}</span></div>
        <div>{{$t('buy.label[2]')}} :{{CardInfo.card_no}}</div>
      </div>
    </div>
    <div class="Panel">
      <div style="display: flex;padding: 15px">
        <div style="line-height: 25px;width: 50%">
          <div style="color: var(--font_color2);font-size: 24px;font-weight: bold">{{parseFloat(CoinInfo.price).toFixed(2)}}</div>
          <div>{{$t('sell.label[3]')}}</div>
          <div style="color: var(--font_subtitle)">USDT</div>
        </div>
        <div style="line-height: 25px;width: 50%">
          <div style="font-size: 22px;color:var(--font_color);font-weight: bold">{{parseFloat(CoinInfo.bestPrice).toFixed(2)}}</div>
          <div>{{$t('sell.label[4]')}}</div>
          <div style="color: var(--font_subtitle)">USDT</div>
        </div>
      </div>
    </div>
    <div class="Panel">
      <h3>
        {{$t('sell.label[5]')}}
      </h3>
        <van-field readonly class="input" :border="false"
                   v-model="BankInfo.price"
                   :placeholder="$t('sell.placeholder[0]')"
        >
          <div slot="right-icon">
        <van-button class="van-button-border" size="min" style="width: 140px;height:
        35px;font-size: 13px" @click="setPrice">
          <span style="font-size: 14px">{{$t('sell.label[7]')}}</span></van-button>
          </div>
      </van-field>
      <h3>
        {{$t('sell.label[6]')}}
      </h3>
      <div>
        <van-field class="input" :border="false"
                   v-model="BankInfo.amount"
                   :placeholder="$t('sell.placeholder[1]')"
        >
          <div slot="right-icon">
        <van-button size="min" style="border-radius:10px;width: 100px;height: 30px" @click="setAmount">
          <span style="color:#FFF;font-size: 14px">{{$t('sell.label[8]')}}</span></van-button>
          </div>
        </van-field>
      </div>
    </div>
    <div class="centerPanel" style="margin-top: 20px;padding: 0px">
      <van-button @click="onSubmit">{{ $t('sell.label[9]') }}</van-button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BindAccount',
  components: {

  },
  props: [],
  data() {
    return {
      tabsActive: 0,
      weixinAcc: '',
      showUsdtType: false,
      CardInfo:{},
      BankInfo:{price:null,amount:null},
      CoinInfo:{price:0,bestPrice:0},
      columns : ['ERC20', 'TRC20', 'OMNI'],
      douyinImg: [],
      kuaishouImg: [],
      accountArr: [],
      tabsList: [],
    }
  },
  computed: {
  },
  watch: {

  },
  created() {
    this.$Model.GetUserInfo()
    this.$Model.GetCommonData(data=>{
      let currs = data.currencylist;
      currs.forEach(item=>{
        if (item['name']=="USDT"){
          this.CoinInfo = item;
          this.CoinInfo['bestPrice'] = item['price'];
        }
      })
    });
    this.$Model.GetBankCardList(data=>{
      this.CardInfo = data.data[0];
    });

    this.$parent.navBarTitle = this.$t('bindAccount.default[0]')
    // this.tabsList = this.InitData.taskclasslist.filter(item=>item.bind_status==1&&item.state==1)
    // this.accountArr = this.tabsList.flatMap(item=>item.bind_type==2?[this.UserInfo[item.bind_field]?[{url:this.ApiUrl+this.UserInfo[item.bind_field]}]:[]]:(this.UserInfo[item.bind_field]||''))
  },
  mounted() {
    this.$Model.GetUserInfo()
  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    setPrice(){
      this.BankInfo.price=this.CoinInfo.bestPrice
    },
    setAmount(){
      this.BankInfo.amount = this.UserInfo.balance;
    },
    onSubmit() {
      if (this.BankInfo.price<=0){
        this.$Dialog.Toast(this.$t('mytask2[9]'));
        return;
      } else if (this.BankInfo.amount<=0){
        this.$Dialog.Toast(this.$t('mytask2[7]'));
        return;
      }else if (parseInt(this.BankInfo.amount)>parseInt(this.UserInfo.balance)) {
        this.$Dialog.Toast(this.$t('mytask2[8]'));
        return;
      }
        this.BankInfo.name = this.CardInfo['name'];
      this.BankInfo.card_no = this.CardInfo['card_no'];
      this.BankInfo.task_type = 2;
      this.BankInfo['task_id'] = 0;
      this.BankInfo['ordertype'] = 2;
      this.$Model.SubmitTaskOrder(
              this.BankInfo,
              (data) => {
                let msg = '';
                switch (data.code){
                  case 1:
                    msg = this.$t('common3[0]');
                    break;
                  case -1:
                    msg = this.$t('mytask2[7]');
                    break;
                  case -2:
                    msg = this.$t('mytask2[8]');
                    break;
                  case -3:
                    msg = this.$t('mytask2[9]');
                    break;
                  case -4:
                    msg = this.$t('common4[7]');
                    break
                  case -5:
                    msg = this.$t('common4[8]');
                    break
                  default:
                    msg = data.code_dec;
                    break
                }
                this.$Dialog.Toast(msg);
                if (data.code==1){
                  this.$router.push("/myTask");
                }
              }
      );
    },
    onChangeType(obj,value){
      this.UserInfo.usdttype = value;
      this.showUsdtType = false;
    },
  }
}
</script>
<style scoped>
.PageBox {
  overflow-y: auto;
}

.van-nav-bar {

}

.van-cell.input >>> .van-field__control{
  height: 30px;
  line-height: 30px;
  background-color:var(--cell_bg);
  border-radius: 20px;
  padding: 10px;

}

.van-cell>>>.van-cell__title{
  font-size: 15px;

}
.van-uploader>>>.van-uploader__upload,.van-uploader>>>.van-uploader__preview-image,.van-uploader>>>.van-uploader__preview{
  margin: 0;
  background-color: #f7f8fa;
  border-radius: 8px;
}
.header0 {
  width: 95%;
  border-radius: 10px;
  display:flex;
  font-size: 17px;
  padding: 5px;
  padding-left: 15px;
  line-height: 40px;
  margin: 0px auto;
}

.MyHeader2 {
  font-size: 19px;
  border-radius: 50%;
  background: pink;
  overflow: hidden;
  width: 42px;
  height: 42px;
  margin-right: 10px;
}
.MyHeader2 img {
  width: 100%;
}
.panelHeader {
  margin-top: 0px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  color: #cccccc;
  width: 40%;
  text-align: center;
  margin-bottom: 10px;
}
.van-cell {
  background: transparent;
}

.PageBox .van-cell>>>.van-cell__title{
  font-size: 15px;
  font-weight: bold;
}

.van-cell--borderless.input >>> .van-field__control {
  color: var(--cell_title) !important;
  padding-left: 15px;
  font-size: 15px;
}
.van-cell >>> .van-field__control::-webkit-input-placeholder {
  font-family: Alibaba;
  color: var(--cell_placeholder);
}

.van-cell >>> .van-field__control::-moz-placeholder {
  font-family: Alibaba;
  color: var(--cell_placeholder);
}

.van-popup {
  background-color: #4e51bf;
}

.hallswipe {
  width: 96%;
  margin-left: 6px;
  padding: 15px;
  height: 60px;
  z-index: 50;
  margin-top: 5px;
  margin-bottom: 5px;
  color:#cccccc;

}

.robotheader{
  font-size: 19px;
  border-radius: 50%;
  background: pink;
  overflow: hidden;
  width: 32px;
  height: 32px;
}
.infoBox {
  margin-left: 10px;
  border-radius: 10px;
  background-color: var(--panel_color);
  padding: 10px;
  margin: 10px;
}
</style>
